import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import Result from './Result';
import { GameStats } from '../../lib/localStorage';
import TopHead from '../header/TopHead';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { Oval } from 'react-loader-spinner';
import { addStatsForCompletedGame, loadStats } from '../../lib/stats';
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
  getStoredIsHighContrastMode,
} from '../../lib/localStorage'
import {
  isWordInWordList,
  isWinningWord,
  getWordOfDay,
  solution as sl,
  findFirstUnusedReveal,
} from '../../lib/words'
import { InfoModal } from '../modals/InfoModal';
import { GAME_COPIED_MESSAGE } from '../../constants/strings';
import { ALERT_TIME_MS } from '../../constants/settings';
import { Alert } from '../alerts/Alert';
import { GuessModel } from '../modals/GuessModel';
import {SignupModel} from '../modals/SignupModel';
import Sponsorshipmodule from '../sponsorship module/Sponsorshipmodule';

// import {
//   STATISTICS_TITLE,
//   RELATED_INFO,
//   GUESS_DISTRIBUTION_TEXT,
//   NEW_WORD_TEXT,
//   SHARE_TEXT,
// } from '../../constants/strings'




const ResultPage = () => {

  const params = useParams()

  // const API_URL = process.env.REACT_APP_API_URL
  const BASE_URL = process.env.REACT_APP_BASE_URL
  let API_URL = 'https://newsword.ai/member-area/api/';
  // const [sessionData, setSessionData] = useState([]);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isShowLoader, setShowLoader] = useState(false)
  const [headerTitle, setHeaderTitle] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [articleUrl, setArticleUrl] = useState(null);
  const [puzzleHeadline, setPuzzleHeadline] = useState('');
  const [wordData, setWordData] = useState({
    id: '',
    word: '',
    run_date: '',
    answer_summery: '',
    answer_url: '',
    logo: '',
  })
  const [currentDate, setCurrentDate] = useState('')
  const [successAlert, setSuccessAlert] = useState('')
  const isGameLost = !!localStorage.getItem('isGameLost');
  const isGameWon = !!localStorage.getItem('isGameWon');
  const [rootUrl, setRootUrl] = useState('');
  const [dateOfArticle, setDateOfArticle] = useState('');
  const [articleDescription, setArticleDescription] = useState('');
  const [sessionStats, setSessionStats] = useState([]);
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('embed');
  const [signupModelToggler, setSignupModelToggler] = useState(false);
  const [signupModuleToggler, setSignupModuleToggler] = useState(false);
  // const [signupModuleURL, setSignupModuleURL] = useState(null);
  const [signupModelHeading,  setSignupModelHeading] = useState(null);
  const [signupModelDescription, setSignupModelDescription] = useState(null);
  const [signupButtonText, setSignupButtonText] = useState(null);
  const [signupbuttonURL, setSignupbuttonURL] = useState(null);
  const [maybeLaterButtonText, setMaybeLaterButtonText] = useState(null);
  const [noThanksButtonText, setNoThanksButtonText] = useState(null);
  const [sponsorshipModule, setSponsorshipModule] = useState(false);
  const [sponsorshipLogo, setSponsorshipLogo] = useState(null);
  const [sponsorshipText, setSponsorshipText] = useState(null);
  const [sponsorshipHyperlink, setSponsorshipHyperlink] = useState(null);
  const [isAddShow, setIsAddShow] = useState(false);
  const ads_desk_finish_screen = "ads-desk-finish-screen";

  useEffect(() => {

    if (isGameWon == false && isGameLost == false) {
      window.location.href = `/${params.company}`
    }
    



    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    var todayDate = yyyy + '-' + mm + '-' + dd
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(today)
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(today)
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(today)
    
    var currentMonthAndDate = mo + ' ' + da
    setCurrentDate(currentMonthAndDate)

    if (params.company) {
     
      setShowLoader(true)

      axios.get(API_URL + `get-company-url?url=${params.company}`)
          .then((response) => {
              if (response.data.success === true) {
                setPuzzleHeadline(response.data.data.headline);
                setClientName(response.data.data.name);
                setArticleUrl(response.data.data.article_url);
                setHeaderTitle(response.data?.data?.puzzle_header_title);
                setRootUrl(response.data?.data?.root_url);
                setDateOfArticle(response.data?.data?.publish_date);
                setArticleDescription(response.data?.data?.article_description);
                setSignupModelToggler(response.data?.data?.signup_alert_modal);
                setSignupModuleToggler(response.data?.data?.signup_alert_module);
                //  setSignupModuleURL(response.data?.data?.signup_alert_module_url);
                 setSignupModelHeading(response.data?.data?.heading);
                 setSignupModelDescription(response.data?.data?.model_description);
                 setSignupButtonText(response.data?.data?.signup_button);
                 setSignupbuttonURL(response.data?.data?.alert_signup_url);
                 setMaybeLaterButtonText(response.data?.data?.maybe_button);
                 setNoThanksButtonText(response.data?.data?.nothanks_button);
                 setSponsorshipModule(response?.data?.data?.sponsorship_module);
                 setSponsorshipLogo(response?.data?.data?.sponsorship_logo);
                 setSponsorshipText(response.data.data.sponsorship_text);
                 setSponsorshipHyperlink(response?.data?.data?.hyperlink);
                 setIsAddShow(response?.data?.data?.ads_toggler);
                // setSessionData(response.data?.data?.puzzle_session);
                // console.log(sessionData);
               

              
              }
                if (response.data.puzzle === true) {
                  // console.log(response.data.data);
                  setWordData(response.data.data)

                } else {
                  if (typeof response.data.data !== 'undefined') {
                    setWordData(response.data.data)
                  }
                }

                if (typeof response.data.data != 'undefined') {
                  if (response.data.data.word) {
                    getWordOfDay(response.data.data.word)
                  }
                }
                let puzzle_id: string =
                      localStorage.getItem('puzzle_id') || ''
                    puzzle_id = atob(puzzle_id)
              if (
                localStorage.getItem('run_date') !=
                response.data.data.run_date ||
                puzzle_id != response.data.data.id

              ){
                saveGameStateToLocalStorage({ guesses: [], solution: '' })
                localStorage.removeItem('welcomePage')
                localStorage.removeItem('resultImageShow')
                localStorage.removeItem('isGameWon');
                localStorage.removeItem('isGameLost');
                if(localStorage.getItem('ModelStatus') == '0' ){
                  localStorage.removeItem('ModelStatus');
                }
                
                window.location.href = `/${params.company}`

              }

            setShowLoader(false)
          });        
    }
   
  }, [])




  const gameState = localStorage.getItem('gameState');
  let guesses: string[] = [];
  if (gameState) {
    try {
      const parsedGameState = JSON.parse(gameState);
      if (Array.isArray(parsedGameState.guesses)) {
        guesses = parsedGameState.guesses;
      }
    } catch (error) {
      console.error('Error parsing gameState:', error);
    }
  }
 

  const [stats, setStats] = useState(() => loadStats())
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isGuessModelOpen, setIsGuessModelOpen] = useState(false)
  
  const isOpen = queryParams.get('isOpen') === 'true';
  const [currentRowClass, setCurrentRowClass] = useState('');
  const Statistics = queryParams.get('Statistics') === 'true';
 
  const showTimer = queryParams.get('showTimer') === 'true';
  const isHardMode = queryParams.get('isHardMode') === 'true';
  const [currentGuess, setCurrentGuess] = useState('')
  const [isRevealing, setIsRevealing] = useState(false)

  if (isGameWon == false && isGameLost == false) {
    return (<div></div>);
  }
  
  return (
    <div>
       {/* style={{minHeight:'100vh'}} */}
      {isShowLoader == true ? (
        <div className="loader-main" >
          <Oval height="50" width="50" color="grey" ariaLabel="loading" />
        </div>
      ) : (
        ''
      )}

      <div>
        <TopHead 
         setIsGuessModelOpen = {setIsGuessModelOpen}
        setIsInfoModalOpen={setIsInfoModalOpen}
         headerTitle={headerTitle}
         isAddShow = {isAddShow}
         deskDynamicClass = {ads_desk_finish_screen}
          />
           {
                sponsorshipModule && (paramValue === 'null' || paramValue == 'null' || !paramValue) && (
                < Sponsorshipmodule
                  sponsorshipLogo = {sponsorshipLogo}
                  sponsorshipText = {sponsorshipText}
                  sponsorshipHyperlink = {sponsorshipHyperlink}
                />
                )
            }
        <div className='result_page_main' >
          <Result
            isAddShow = {isAddShow}
            signupModuleToggler = {signupModuleToggler}
            signupbuttonURL = {signupbuttonURL}
            signupModelDescription = {signupModelDescription}
            rootUrl = {rootUrl}
            dateOfArticle ={dateOfArticle}
            articleDescription = {articleDescription}
            articleUrl={articleUrl}
            puzzleHeadline={puzzleHeadline}
            isOpen={isOpen}
            handleClose={() => { }}
            guesses={Array.isArray(guesses) ? guesses : []}
            gameStats={stats as GameStats}
            // gameStats={sessionData}
            isGameLost={isGameLost}
            isGameWon={isGameWon}
            date={currentDate}
            Statistics={Statistics}
            wordData={wordData}
            showTimer={showTimer}
            headerTitle ={headerTitle}
            handleShare={() => {
              setSuccessAlert(GAME_COPIED_MESSAGE)
              return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
            }}
            isHardMode={isHardMode}
           
          />
        </div>
        <InfoModal
          clientName={clientName}
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
          {
            !isShowLoader && signupModelToggler && (!localStorage.getItem('ModelStatus')) && (paramValue === 'null' || paramValue == 'null' || !paramValue ) && (
              <SignupModel
             
                signupModelHeading = {signupModelHeading}
                signupModelDescription = {signupModelDescription}
                signupButtonText = {signupButtonText}
                signupbuttonURL = {signupbuttonURL}
                maybeLaterButtonText = {maybeLaterButtonText}
                noThanksButtonText = {noThanksButtonText}
                isOpen={signupModelToggler}
                headerTitle={headerTitle}
                handleClose={() => setSignupModelToggler(false)}
              />
            )
          }
         <GuessModel
                guesses={guesses}
                currentGuess={currentGuess}
                isRevealing={isRevealing}
                currentRowClassName={currentRowClass}
                isOpen={isGuessModelOpen}
                handleClose={() => setIsGuessModelOpen(false)}
              />
        {/* <Alert
          message={successAlert}
          isOpen={successAlert !== ''}
          variant="share"
          topMost={true}
        /> */}
      </div>
    </div>
  );
};

export default ResultPage;
